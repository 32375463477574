import {
    createRouter,
    createWebHistory
} from 'vue-router';
import Home from '../views/Home';
import Props from '../views/Props';
import CV from '../views/CV';
import PageNotFound from '../views/PageNotFound';
import KBJU from '../views/KBJU';
import Recipes from '../views/Recipes';
import Auth from '../views/Auth';
import Registration from '../views/Registration';
import Profile from '../views/Profile';
import Charisma from '../views/Charisma';
import DBuilder from '../views/DBuilder';
import Tracker from '../views/Tracker';
import Test2 from '../views/Test2';
import Room from '../views/Room';
import Bento from '../views/Bento';
import Bookmarks from '../views/Bookmarks';
import {
    isAuthenticated
} from '../utils/auth';

let routes = null;
let dsekonRoutes = [{
    path: '/',
    name: '',
    component: Home
}, {
    path: '/props.css',
    name: 'CSS Props',
    component: Props
}, {
    path: '/mycv',
    name: 'CV',
    component: CV
}, {
    path: '/kbju',
    name: 'KBJU',
    component: KBJU
}, {
    path: '/recipes',
    name: 'Recipes',
    component: Recipes
}, {
    path: '/registration',
    name: 'Registration',
    component: Registration
}, {
    path: '/auth',
    name: 'Auth',
    component: Auth
}, {
    path: '/tracker',
    name: 'Tracker',
    component: Tracker
}, {
    path: '/charisma',
    name: 'Charisma',
    component: Charisma
}, {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
        requiresAuth: true
    }
}, {
    path: '/dbuilder',
    name: 'DBuilder',
    component: DBuilder
}, {
    path: '/bento',
    name: 'Bento',
    component: Bento
}, {
    path: '/test',
    name: 'Test',
    component: Test2
}, {
    path: '/room/:roomName',
    name: 'Room',
    component: Room
}, {
    path: '/:pathMatch(.*)*',
    alias: '/404',
    name: 'Page not found',
    component: PageNotFound
}];

if (process.env.VUE_APP_PROJECT === 'Letter') {
    routes = [{
        path: '/',
        name: 'DBuilder',
        component: DBuilder
    }]
} else if (process.env.VUE_APP_PROJECT === 'bookmarks') {
    routes = [{
        path: '/',
        name: 'Bookmarks',
        component: Bookmarks
    }]
} else {
    routes = [...dsekonRoutes]
}

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkActiveClass: 'active',
    linkExactActiveClass: 'active',
    routes
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth && !isAuthenticated()) {
        next('/auth');
    } else {
        next()
    }
});

export default router
