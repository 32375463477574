import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["innerHTML"]

import {
        reactive,
        onMounted
    } from 'vue';
    import {
        gsap,
        ScrollTrigger
    } from "gsap/all";

    
export default {
  __name: 'Bookmarks',
  setup(__props) {

    gsap.registerPlugin(ScrollTrigger);

    const cards = reactive([{
        title: "<span>D</span>ReadMore - JS plugin for multi-line content truncation with show/hide functionality",
        text: "Read more",
        btn: {
            href: "https://dreadmore.dsekon.com"
        }
    }, {
        title: "<span>D</span>Builder Email - Figma plugin for exporting custom HTML email templates",
        text: "Read more",
        btn: {
            href: "https://e.dsekon.com"
        }
    }]);

    const img = new Image();
    let counter = 0;

    img.onload = function() {
        if (this.width !== 16) {
            cards[counter].img = `https://www.google.com/s2/favicons?sz=180&domain_url=${cards[counter].btn.href}`;
        }

        counter++;

        if (cards.length > counter) {
            img.src = `https://www.google.com/s2/favicons?sz=180&domain_url=${cards[counter].btn.href}`;
        }
    }

    img.src = `https://www.google.com/s2/favicons?sz=180&domain_url=${cards[counter].btn.href}`;


    onMounted(() => {
        if (window.innerWidth > 1024) {
            document.getElementById('Cards').onmousemove = e => {
                for (const card of document.getElementsByClassName('card-test')) {
                    const rect = card.getBoundingClientRect(),
                        x = e.clientX - rect.left,
                        y = e.clientY - rect.top;
                    card.style.setProperty('--mouse-x', `${x}px`);
                    card.style.setProperty('--mouse-y', `${y}px`);
                };
            }
        }
    });

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style['bento'])
  }, [
    _createElementVNode("section", {
      id: "Cards",
      class: _normalizeClass(_ctx.$style.section)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style['container'])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style['col'])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style['profile'])
          }, [
            _createElementVNode("img", {
              class: _normalizeClass(_ctx.$style['profile__avatar']),
              src: "/img/Avatar.png"
            }, null, 2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style['profile__content'])
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style['profile__title'])
              }, " DSekon ", 2),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style['profile__text'])
              }, " Lead Front-end Developer ", 2)
            ], 2)
          ], 2)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style['col'])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style['card-block'])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["offset--items_m", _ctx.$style['card-block__list']])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cards, (item) => {
                return (_openBlock(), _createElementBlock("a", {
                  key: item,
                  class: _normalizeClass(["card-test type-3", _ctx.$style['card']]),
                  href: item.btn.href,
                  target: "_blank"
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['card__content'])
                  }, [
                    (item.img)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          class: _normalizeClass(_ctx.$style['card__icon']),
                          src: item.img,
                          alt: ""
                        }, null, 10, _hoisted_2))
                      : _createCommentVNode("", true),
                    _createElementVNode("h3", {
                      class: _normalizeClass(_ctx.$style['card__title']),
                      innerHTML: item.title
                    }, null, 10, _hoisted_3),
                    _createElementVNode("p", {
                      class: _normalizeClass(_ctx.$style['card__text'])
                    }, _toDisplayString(item.btn.href.replace('https://', '')), 3)
                  ], 2)
                ], 10, _hoisted_1))
              }), 128))
            ], 2)
          ], 2)
        ], 2)
      ], 2)
    ], 2)
  ], 2))
}
}

}